import React from 'react'
import styles from './Container.module.scss'
import cn from 'classnames'

export const Container = ({children, style = {}, classNames = ''}) => {
  return (
    <div className={cn(styles.container, classNames)} style={{...style}}>
      {children}
    </div>
  )
}
