import React from 'react'
import Head from 'next/head'
import { Container } from '../Container/Container'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

function Page404 (props) {

  return (
    <div className="static">
      <Head>
        <title>404</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width"/>
      </Head>
      <Navbar allTextPage={props.pages} />
      <Container style={{display: 'flex'}}>
        <img src="/404.svg" alt="404 page" style={{margin: 'auto'}} />
      </Container>
      <Footer allTextPage={props.pages} />
    </div>
  )
}

export default Page404
